import React from 'react';

import SingleImage from "../components/Intro/SingleImage";
import Layout from "../components/Sections/Layout";
import Loader from "../components/Sections/Loader";
import Header from "../components/Sections/Header";
import ContactThanks from "../components/Sections/ContactThanks";
import Footer from "../components/Sections/Footer";
import ToTop from "../components/Sections/ToTop";

const Thanks = () => (

    <Layout
        pageTitle="Taggr - Provable Authenticity & Ownership"
        colorSchema="../colors/blue.css"
    >
        <Loader />
        <Header nav="home" />
        <SingleImage />
        <ContactThanks />
        <Footer />
        <ToTop />
    </Layout>

)

export default Thanks;