import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const ContactThanks = (props) => {
  return (
    <section id="contact" className={props.className}>
      {/* <!-- Container --> */}
      <Container>
        <Row>
          {/* <!-- Contact info --> */}
          <Col className="contact-info col-12 col-lg-4 res-margin">
            <h5>
              <span className="icon icon-basic-geolocalize-05"></span>
              Operating Location
            </h5>
            <p>
              333 Bay St., Unit 2400
              <br />
              Toronto, Ontario M5H 2R2
              <br />
              Canada
            </p>

            <h5>
              <span className="icon icon-basic-smartphone"></span>
              Online
            </h5>
            <p>
              <a href="https://discord.gg/Q3m5vYbDyf">Discord</a>,{" "}
              <a href="https://twitter.com/TaggrNFT">Twitter</a>,{" "}
              <a href="https://t.me/+v9A3mWhggqQ4NTI5">Telegram</a>
            </p>

            <h5>
              <span className="icon icon-basic-mail"></span>
              Email Address
            </h5>
            <p>
              <a href="mailto:info@taggr.io">info@taggr.io</a>
            </p>

            <h5>
              <span className="icon icon-basic-clock"></span>
              Operating Hours
            </h5>
            <p>09:00AM to 06:00PM Eastern Timezone</p>
          </Col>

          {/* <!-- Contact form --> */}
          <Col className="col-12 col-lg-8">
            <div className="contact-form-result">
              <h4>Thank you for the e-mail!</h4>
              <p>Your message has already arrived! We'll contact you shortly.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContactThanks;
